import React from 'react';
import Button from './buttons/Button';
import { CheckBurgerIcon, PauseSmallIcon, PlaySmallIcon } from '../icons';
import { useNavigate } from 'react-router-dom';
import {
  loaderSvg,
  parseAndBoldText,
  SafeHtmlComponent,
} from '../utils/functions';
import { TASK_STATUSES } from '../view/Task/Task';
import { TaskMetric } from '../types/models/task';

interface ShiftCheckTableProps {
  data: TaskMetric[];
  loader: boolean;
  options: any[],
  setSelectedData?: (data: any) => void;
  
  taskButtonOnClick?: (taskId: number, materialId: number) => void;
}

const ShiftCheckTable: React.FC<ShiftCheckTableProps> = ({
  data,
  loader,
  options,
  taskButtonOnClick,
}) => {

  return (
    <table className="table-auto w-full border-collapse border border-slate-200">
      <thead className="bg-[#F2F3F5]">
        <tr className="text-[14px] font-nunito font-medium text-left [&>*]:px-[16px] [&>*]:py-[17px]">
          <th>Тип задачи</th>
          {options?.length > 0 &&
            options?.map((value: any, index: number) => (
              <th key={index}>{value?.name}</th>
            ))}
          <th>Рулон</th>
          <th>Длина изделия</th>
          <th>Количество</th>
          <th>Действия</th>
        </tr>
      </thead>
      {loader ? (
        <tr>
          <td
            colSpan={options.length + 4}
            className="text-center p-[20px] w-full"
          >
            {loaderSvg()}
          </td>
        </tr>
      ) : data.length > 0 ? (
        data.map((task: TaskMetric, index: number) => (
            task.items.map((item, itemIndex) => (
          <tr
            key={index}
            className={
              'text-[14px] cursor-pointer hover:bg-[#F2F3F5] font-nunito font-normal text-left [&>*]:px-[16px] [&>*]:py-[17px] [&>*]:border-collapse [&>*]:border [&>*]:border-slate-200 ' +
              'bg-[#F2F3F5]'
            }
          >
            <td>
              {task?.task_type === 'standard' ? 'Стандартная' : task?.task_type === 'flat' ? 'Гладкий лист': task?.task_type === 'defect' ? 'Брак' : task?.task_type === 'docat' ? 'Докат' : ''}
            </td>
            {options?.length > 0 &&
              options?.map((voption: any, index_option: number) => (
                <td key={index_option}>
                  {task?.options?.length > 0 &&
                    task?.options.map((v: any, i: number) => {
                      if (v?.option_id === voption?.id) {
                        return (
                          <div
                            key={i}
                            className="flex gap-[5px] justify-center items-center"
                          >
                            <span>{v?.value}</span>
                          </div>
                        );
                      } else {
                        return <td key={i}></td>;
                      }
                    })}
                </td>
              ))}
            <td>
              { task?.materials?.find((material) => material.material_id === item.material_id)?.roll_id}
            </td>
            <td>
              {item.length ? item.length * 1000 : ''}
            </td>
            <td>
              {item.quantity}
            </td>
            <td className="">
              <div className="flex flex-col gap-[8px] min-w-[182px]">
                <Button
                  text="Заменить рулон в задаче"
                  onClick={() => {taskButtonOnClick && taskButtonOnClick(task.id, item.material_id)}}
                />
              </div>
            </td>
          </tr>
        ))
    ))) : (
        <tr>
          <td
            colSpan={options.length + 4}
            className="text-center p-[20px] w-full"
          >
            Нет данных
          </td>
        </tr>
      )}
    </table>
  );
};

export default ShiftCheckTable;
